<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'AppMultiselect',
  components: {
    Multiselect,
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    value: {
      default: null
    },

    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    placeholder: {
      type: String,
      default: 'Selecione os grupos'
    },

    shouldCloseOnSelect: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array,
      default: () => {
        return []
      }
    },

    dark: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mutableValue: null,
      shouldShowSelectAll: true
    }
  },

  computed: {
    allSelected () {
      if (!this.options || !this.mutableValue) return

      return this.options.length === this.mutableValue.length
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value) {
          this.mutableValue = value
        }
      }
    }
  },

  created () {
    this.mutableValue = this.value !== null ? this.value : null
  },

  methods: {
    onSelect (value) {
      this.$emit('onSelect', value)
    },

    onRemove (value) {
      this.$emit('onRemove', value)
    },

    updateSelectAllButton () {
      this.shouldShowSelectAll = this.$refs.options && this.$refs.options.filteredOptions.length === this.options.length
    }
  }
}
</script>

<template>
  <div>
    <Multiselect
      ref="options"
      v-model="mutableValue"
      open-direction="bottom"
      track-by="value"
      label="text"
      multiple
      :options="options"
      :clear-on-select="false"
      :placeholder="placeholder"
      :class="{'is-dark': dark}"
      :close-on-select="shouldCloseOnSelect"
      :select-label="$t('global:form.groups.select-label')"
      :selected-label="$t('global:form.groups.selected-label')"
      :deselect-label="$t('global:form.groups.deselect-label')"
      :no-result="$t('global:form.groups.no-result')"
      @select="onSelect"
      @remove="onRemove"
      @search-change="updateSelectAllButton"
    >
      <li
        v-if="shouldShowSelectAll"
        slot="beforeList"
        role="option"
        class="multiselect__element"
        :class="{'selected': allSelected}"
        @click="allSelected ? onRemove({ value: 'all' }) : onSelect({ value: 'all' })"
      >
        <span
          class="multiselect__option multiselect__option--all"
          :class="{'multiselect__option--highlight multiselect__option--selected': allSelected}"
        >
          <span>{{ allSelected ? 'Remover Todos' : 'Selecionar Todos' }}</span>
        </span>
      </li>

      <li
        slot="noResult"
      >
        <span>{{ $t('global:form.groups.no-result') }}</span>
      </li>
    </Multiselect>

    <ValidationMessage :validation="validation" />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect {
  .multiselect__option {
    &.multiselect__option--highlight {
      background: #eee;
      color: #333438;

      &::after {
        background: #ddd;
        color: #333438;
      }
    }

    &.multiselect__option--selected {
      background: #005EB8;
      color: white;

      &::after {
        background: #005EB8;
        color: #fff;
      }
    }

    &.multiselect__option--all {
      &::after {
        display: none;
      }
    }

    &:hover {
      @extend .multiselect__option--highlight;
    }
  }

  .multiselect__tags {
    .multiselect__tag {
      background: #005EB8;

      .multiselect__tag-icon {
        &:hover {
          background: #152E69;
        }

        &::after {
          color: #fff;
        }
      }
    }
  }

  &.is-dark {
    .multiselect__input {
      background: transparent;
      color: white;

      &::placeholder {
        color: white;
      }
    }

    .multiselect__tags {
      background: transparent;

      .multiselect__tag {
        background: white;
        color: #333438;

        .multiselect__tag-icon {
          &::after {
            color: #666;
          }

          &:hover {
            background: transparent;

            &::after {
              color: #333438;
            }
          }
        }
      }
    }
  }
}
</style>
